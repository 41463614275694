import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useMemo, useState } from "react";
import { CustomButtonWithIcon } from "../../components/custom-button";
import HeaderComponent from "../../components/HeaderComponent";
import ContactContext from "../../context/contact-context";
import BrokerDetails from "../../features/staff/broker-details";
import BrokerModal from "../../features/staff/broker-modal";
import DeleteBrokerModal from "../../features/staff/delete-broker-modal";
import {
  useStaffInfo,
  useStaffList,
  useStaffListByCompanyId,
} from "../../hooks/staff.hooks";
import { useMyInfo } from "../../hooks/user.hooks";
import { STAFF_ROLE, USER_ROLE } from "../../commons/enum";
import { useCompanyList } from "../../hooks/company.hooks";

const BrokerManagement = () => {
  const { data: userInfo } = useMyInfo();
  const { data: staffInfo } = useStaffInfo();
  const { data: brokerList = [] } = useStaffList();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedBrokerDetails, setSelectedBrokerDetails] = useState({});
  const { data: companyList = [] } = useCompanyList();
  const [filterData, setFilterData] = useState({
    company: "",
    staffRole: [],
  });

  const { data: brokerListByCompany = [] } = useStaffListByCompanyId(
    filterData.company?.id
  );

  const staffList = useMemo(() => {
    if (filterData?.company) {
      return brokerListByCompany;
    }
    return brokerList;
  }, [brokerList, brokerListByCompany, filterData?.company]);

  const companiesBelongToCompanyAdmin = useMemo(() => {
    return (
      staffInfo?.staffRoles
        .filter((staffRole) => staffRole.role === STAFF_ROLE.COMPANY_ADMIN)
        .map((staffRole) => staffRole.companyId) || []
    );
  }, [staffInfo?.staffRoles]);

  const filteredCompanyList = useMemo(() => {
    return companyList.filter((company) =>
      companiesBelongToCompanyAdmin.includes(company.id)
    );
  }, [companiesBelongToCompanyAdmin, companyList]);

  const handleDialog = () => {
    setIsOpenDialog(!isOpenDialog);
  };

  const handleDeleteModal = (value) => {
    setDeleteModalOpen(value);
  };

  const handleModal = (value) => {
    setModalOpen(value);
    if (!value) {
      setSelectedBrokerDetails({});
    }
  };

  useEffect(() => {
    if (userInfo.userRole === USER_ROLE.STAFF)
      setFilterData((prevFilterData) => {
        return {
          ...prevFilterData,
          company: filteredCompanyList?.[0],
        };
      });
  }, [filteredCompanyList, userInfo]);

  return (
    <ContactContext.Provider
      value={{
        handleDialog,
      }}
    >
      <HeaderComponent />
      <div className="broker-page">
        <div className="broker-page__feature">
          <div className="broker-page__filter-container">
            {(userInfo?.userRole === USER_ROLE.ADMIN ||
              companiesBelongToCompanyAdmin.length > 2) && (
              <Autocomplete
                disablePortal
                id="company-filter"
                options={
                  userInfo?.userRole === USER_ROLE.ADMIN
                    ? companyList
                    : filteredCompanyList
                }
                value={filterData?.company}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                onChange={(event, value) => {
                  setFilterData((prevFilterData) => {
                    return { ...prevFilterData, company: value };
                  });
                }}
                getOptionLabel={(option) => option.name || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Company" />
                )}
              />
            )}
          </div>
          <div className="broker-page__add-button">
            <CustomButtonWithIcon
              label="Add Staff"
              icon={<AddIcon />}
              buttonType="secondary"
              onClick={handleModal}
            />
            <BrokerModal
              modalStatus={modalOpen}
              handleModal={handleModal}
              brokerDetails={selectedBrokerDetails}
            />
            <DeleteBrokerModal
              modalStatus={deleteModalOpen}
              handleModal={handleDeleteModal}
              userId={selectedBrokerDetails?.userId}
            />
          </div>
        </div>

        <div className="broker-page__broker-list">
          {staffList.map((broker) => (
            <BrokerDetails
              key={broker.id}
              brokerDetails={broker}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              handleBrokerDetails={setSelectedBrokerDetails}
            />
          ))}
        </div>
      </div>
    </ContactContext.Provider>
  );
};

BrokerManagement.propTypes = {};

export default BrokerManagement;
